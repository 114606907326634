import { NextComponentType } from 'next';
import { useRouter } from 'next/router';
import Error from 'next/error';
import { useUserContext } from '@/contexts/user';
import Login from '@/views/Login';
import ResetPassword from '@/views/ResetPassword';
import ResetPasswordVerify from '@/views/ResetPasswordVerify';
import { routes } from '@/constants/routes';
import { VendorRole } from '@/constants/roleRestrictions';
import { useRestaurantContext } from '@/contexts/restaurant';
import { useTranslation } from '@/hooks/translations';
import { ReactNode } from 'react';

function withAuth(Component: NextComponentType, restrictRoles?: string[]) {
    const Auth = (props: JSX.IntrinsicAttributes & { children?: ReactNode }) => {
        const { user } = useUserContext();
        const router = useRouter();
        const { restaurant } = useRestaurantContext();
        const { t } = useTranslation('common');

        const enableShiftMaskforVendorAnalyst =
            (user?.userData?.role === VendorRole.VendorAnalyst &&
                restaurant?.config?.enableShiftMaskforVendorAnalyst) ||
            false;

        if (router.pathname === routes.RESET_PASSWORD) {
            return <ResetPassword />;
        }

        if (router.pathname === routes.RESET_PASSWORD_VERIFY) {
            return <ResetPasswordVerify />;
        }

        if (!user) {
            return <Login />;
        }

        if (enableShiftMaskforVendorAnalyst && router.pathname !== routes.TABLE_VIEW) {
            router.push(routes.TABLE_VIEW);
            return null;
        }

        if (restrictRoles && !restrictRoles.includes(user?.userData?.role)) {
            console.log(restrictRoles, user);
            if (router.pathname === routes.HOME && user?.userData?.role === VendorRole.VendorAnalyst) {
                router.push(routes.TABLE_VIEW);
                return null;
            }
            return <Error statusCode={403} />;
        }

        if (router.pathname === routes.TAX && !restaurant?.config?.qsrTaxManagement) {
            return <Error statusCode={403} title={t('You do not have access to this page')} />;
        }

        return <Component {...props} />;
    };

    if (Component.getInitialProps) {
        Auth.getInitialProps = Component.getInitialProps;
    }

    return Auth;
}

export default withAuth;
